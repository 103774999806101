// Import Fuse core library
@import "@fuse/scss/core";

// Import app.theme.scss
@import "app/app.theme";

// Quill rich text editor
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

[hidden] {
  display: none !important;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

@mixin container-padding {
  padding: 32px 24px;
}

.card {
  background: #FFFFFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 12px 26px;

  &__title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: black;
  }

  & + .card {
    margin-top: 24px;
  }
}

.spacer {
  flex: 1 1 auto;
}

#path > #way {
  stroke: #E04343;
  stroke-width: 15px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

svg {
  .hidden {
    display: none !important;
  }
  #objects > g {
    cursor: pointer;

    &.active-object {
      & > [id^="Shape"], & > [id^="shape"] {
        fill: #673ab7;
      }

      &:hover > [id^="Shape"], &:hover > [id^="shape"] {
        fill: #673ab7;
      }

      & > [id^="logo"] {
        fill: white !important;
      }
    }
  }
}

.space {
  display: flex;
  flex: 1;
}

.box {
  margin: 15px;
  background: white;
  padding: 15px;
  @each $breakpoint in map-keys($breakpoints) {
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      width: map-get($container-max-widths, $breakpoint);
      margin: 15px auto;
    }
  }
}

.sticky {
  position: sticky;
  top: 0;
  background: white;
  border-bottom: none !important;
}

.search {
  height: 40px;
  width: 100%;
  display: block;
  outline: none;
  border: 0;
  padding: 0 20px;
  background: transparentize(#fff, 0.9);
  min-width: 300px;
  font-size: 15px;
  color: white;

  &::placeholder {
    color: transparentize(#fff, 0.7);
  }
}

[mat-fab].bottom-right {
  position: absolute;
  bottom: 10px;
  right: 90px;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.flex {
  flex: 1;
  min-width: 0;
  min-height: 0;

  &.auto {
    flex: 0 0 auto;
    width: auto;
    height: auto;
  }
}

.controls {
  flex: 0 0 40px !important;
}

.mat-header-row {
  z-index: 1;
}

mat-row {
  outline: none;
}

mat-selection-list {
  outline: none;
}

.order-snackbar {
  background: #673ab7;
}

.mat-header-cell .mat-sort-header-container.mat-sort-header-sorted .mat-sort-header-arrow {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

// quill bg fix
.mat-form-field-appearance-fill .mat-form-field-flex {
  background: white!important;
}

.empty-table {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.loading {
  position: absolute;
  top: -24px;
  left: -24px;
  right: -24px;
  bottom: -24px;
  background: transparentize(#fff, 0.5);
}

.facade-tabs {
  .tab-label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;

    button {
      visibility: hidden;
    }

    &:hover {
      button {
        visibility: visible;
      }
    }
  }
}
