@mixin forgot-password-theme($theme) {

    $background: map-get($theme, background);
    $is-dark: map-get($theme, is-dark);

    #forgot-password {

        #forgot-password-form-wrapper {

            #forgot-password-form {
                @if ($is-dark) {
                    background: mat-color($fuse-navy, 600);
                } @else {
                    background: map-get($background, card);
                }
            }
        }
    }
}
